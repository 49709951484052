import  { request } from './RailsRequestsMainController'

export const setInSession = async (assignment) => {
  return request('/api/v1/assignments/set_in_session', 'post', { assignment: assignment})
}

export const getData = async (assignment_name) => {
  return request('/api/v1/assignments/get_data', 'post', { assignment_name: assignment_name})
}

export const checkForExistingValidatedMatch = async (remote_id) => {
  return request('/api/v1/assignments/check_for_existing_validated_match', 'post', { remote_id: remote_id})
}

export const updateRecord = async (id, remoteIdToInvalidate, status ) => {
  body = {
   remote_id: id,
   remote_id_to_invalidate: remoteIdToInvalidate,
   status: status
  }

  return request('/api/v1/assignments/update_remote_term_status', 'post', body)
}

export const asyncSearchForDataPlatformTerms = async (input_text, entity_type) => {
  if (input_text.length < 3) return

  return request('/api/v1/assignments/search_for_data_platform_terms', 'post', { remote_name: input_text, entity_type: entity_type })
}

export const saveMatch = async (remote_id, data_platform_id) => {
  return request('/api/v1/assignments/save_match', 'post', { remote_id: remote_id, data_platform_id: data_platform_id })
}

export const deleteMatch = async (remote_id) => {
  return request('/api/v1/assignments/delete_match', 'post', { remote_id: remote_id })
}

export const applySafeMatch = async (ids) => {
  return request('api/v1/assignments/apply_safe_match_for_remote_terms', 'post', { remote_ids: ids })
}

export const updateStatus = async (assignment_name, status) => {
  return request('api/v1/assignments/update_status', 'post', { assignment_name: assignment_name, status: status })
}
