import React from 'react'
import PropTypes from 'prop-types'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MuiDownshift from 'mui-downshift'
import useDebouncedFetch from '../utils/hooks/useDebouncedFetch'

const RemoteAutocomplete = ({ onSelect, asyncFetchFunction, reference }) => {
  const { inputText, setInputText, search } = useDebouncedFetch(asyncFetchFunction)

  return (
    <MuiDownshift
      ref={reference}
      variant={'standard'}
      getListItem={
        ({ getItemProps, item }) => {
          return (item ?
            (
              <ListItem button {...getItemProps(item)}>
                <ListItemText primaryTypographyProps={{ style: { fontSize: '1.3rem' } }} primary={item.name} />
              </ListItem>
            ) :
            search.loading ? (
              <ListItem button disabled>
                <ListItemText  primaryTypographyProps={{ style: { fontSize: '1.3rem' } }} primary={<span style={{ fontStyle: 'italic' }}>Loading...</span>} />
              </ListItem>
            ) : !search.result || search.result.length === 0 ? (
              <ListItem button disabled>
                <ListItemText  primaryTypographyProps={{ style: { fontSize: '1.3rem' } }} primary={<span style={{ fontStyle: 'italic' }}>No items found</span>} />
              </ListItem>
            ) : (
              null
            )
          )
        }
      }
      includeFooter={true}
      items={search.result || []}
      itemToString={item => (item ? item.name : '')}
      loading={search.loading}
      showEmpty={true}
      onChange={onSelect}
      onStateChange={async changes => {
        // Do not trigger asyncFetchFunction if there is no input value
        const validInputValue = typeof changes.inputValue === 'string' && changes.inputValue.length > 3
        // Do not trigger asyncFetchFunction upon selecting item
        const itemSelectedEvent = changes.type === '__autocomplete_click_item__'

        if (validInputValue && !itemSelectedEvent) {
          setInputText(changes.inputValue)
        } else if (changes.inputValue?.length === 0) {
          setInputText('')
        }
      }}
    />
  )
}

RemoteAutocomplete.propTypes = {
  onSelect: PropTypes.func,
  asyncFetchFunction: PropTypes.func,
  reference: PropTypes.object
}

export default RemoteAutocomplete
