import { createSlice } from '@reduxjs/toolkit'

export const generalSlice = createSlice({
  name: 'general',
  initialState: {
    pending_assignments: [],
    completed_assignments: [],
    current_assignment: null,
    current_mapping_entity_type: null,
    error_message: null
  },
  reducers: {
    set_pending_assignments: (state, action) => {
      state.pending_assignments = action.payload
    },
    set_completed_assignments: (state, action) => {
      state.completed_assignments = action.payload
    },
    set_current_assignment: (state, action) => {
      state.current_assignment = action.payload
    },
    set_current_mapping_entity_type: (state, action) => {
      state.current_mapping_entity_type = action.payload
    },
    set_error_message: (state, action) => {
      state.error_message = action.payload
    }
  }
})

export const {
  set_pending_assignments,
  set_completed_assignments,
  set_current_assignment,
  set_current_mapping_entity_type,
  set_error_message
} = generalSlice.actions

export default generalSlice.reducer
