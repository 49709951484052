import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { Button } from '@material-ui/core'

const DetailsPopUp = ({ details, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textAlign: 'center'}}>
        <span style={{ fontSize: '20px' }}>
          Details
        </span>
      </DialogTitle>
      <DialogContent>
        <div className="popup" style={{ padding: '20px' }}>
          <div className="popup-content" style={{ maxHeight: '600px', overflowY: 'auto', marginRight: '50px' }}>
            <ul>
              {Object.entries(details).map(([key, val]) => (
                <li key={key}>
                  <strong>{key}:</strong> {val}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Button
          style={{
            float: 'right',
            padding: '6px 8px 6px 8px',
            marginLeft: '10px',
            backgroundColor: '#ffc700',
            borderRadius: '5px',
            fontFamily: "'Comfortaa', sans-serif",
            color: 'white',
            fontSize: '12px',
            textTransform: 'none'
          }}
          onClick={onClose}>
          Close
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default DetailsPopUp
