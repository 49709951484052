import { set_pending_assignments, set_completed_assignments, set_current_assignment, set_error_message } from '../redux/reducers/general'

const initializeGeneralProps = (dispatch, props) => {
  dispatch(set_pending_assignments(props.pending_assignments))
  dispatch(set_completed_assignments(props.completed_assignments))
  dispatch(set_current_assignment(props.current_assignment))
  dispatch(set_error_message(props.error_message))
}

export default initializeGeneralProps
