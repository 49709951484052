import { applyMiddleware, createStore } from 'redux'
import { thunk } from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers'

export default function configureStore (preloadedState) {
	let store

	if (process.env.NODE_ENV === 'production') {
		store = createStore(rootReducer, preloadedState, applyMiddleware(thunk))
	} else {
		const composedStore = composeWithDevTools(applyMiddleware(thunk))
		store = createStore(rootReducer, preloadedState, composedStore)
	}

	return store
}
