import React from 'react'

const Loading = ({ style }) => {
  return (
    <center>
      <img style ={style} src='/assets/loader.gif' alt="Loading" />
    </center>
  )
}

export default Loading
