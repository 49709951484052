export const TOKEN = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')

export const request = async(url, method, body, return_raw_response) => {
  const response = await fetch(url, {
    method,
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
      'X-CSRF-Token': `${TOKEN}`
    },
    credentials: 'include',
    body: body ? JSON.stringify({ ...body, authenticity_token: TOKEN }) : null
  })

  return !return_raw_response && response ? response.json() : response
}

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response
  }
  else {
    const error = new Error(`HTTP Error ${response.statusText}`)

    error.status = response.statusText
    error.response = response
    console.log(error)
    return error
  }
}
